<template>
  <div class="">
    <Navbar/>
    <Menu/>
  </div>
  <div class="container uk-padding" :class="{'container_active': ($store.state.winW > 960 && $store.state.activeNav)}" id="container__main">
    <router-view/>
  </div>
</template>

<script>
import Navbar from '@/layouts/Navbar'
import Menu from '@/layouts/Scoped/Admin/Menu'

  export default {
    components: {
      Navbar, Menu
    },

    created() {
      if (!Object.keys(this.$store.state.user).length) {

        this.$watch('$store.state.user', () => {
          this._checkAccessPanel()
        })

        this.$store.dispatch('getUser')
      } else {
        this._checkAccessPanel()
      }

      document.body.setAttribute('uk-img', '');
      document.body.setAttribute("data-src", '/images/bg.jpg');
    },

    methods: {
      _checkAccessPanel() {
        if (this.$store.state.user.role == 0) {
          this.$router.push({path: '/'})
        }
      }
    }

  }
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
