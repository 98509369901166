<template>
  <div class="nav__menu-shadow"
    v-show="($store.state.activeNav && $store.state.winW <= 960)"
    @click="toggleNav()"
    >
  </div>
  <div class="nav__menu uk-background-default uk-box-shadow-medium z-index-10" :class="{'nav__menu-active': $store.state.winW > 960 && $store.state.activeNav}">
    <div class="uk-grid uk-flex-between uk-padding-small uk-flex-middle">
      <div class="uk-grid uk-grid-small uk-flex-middle">
        <div>
          <span @click="toggleNav" class="material-icons uk-text-middle cr-pointer">menu</span>
        </div>
        <div class="">

            <div v-if="fioUser" class="">Мир тебе, {{fioUser}}!</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import UIkit from 'uikit'


  export default {
    data() {
      return {
      }
    },

    methods: {
      toggleNav() {
        this.$store.commit('setStatusBurger', 1) //Click one
        this.$store.dispatch('toggleNav')
      },
    },

    computed: {
    fioUser() {
      if (Object.keys(this.$store.state.user).length) {
        let user = this.$store.state.user
        return `${user.first_name}`
      } else {
        return ''
      }
    }
    }

}
</script>

<style scoped>
.avatar_nav {
  height: 35px;
  width: 35px;
  background: #f5f5f5
}

.avatar_nav span {
  font-size: 18px
}

.nav__menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  transition: 0.2s;
}

.nav__menu-active {
  width: calc(100% - 300px);
}

.nav__menu-shadow {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: fixed;
  background: rgb(0 0 0 / 44%);
}

</style>
